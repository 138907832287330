import { IllustantionDiv, MainButton, ShareListDiv } from "./style/mainStyle";
import { MbtiTitle } from "./style/questionStyle";
import {
  EndingTageDiv,
  GoSurveyDiv,
  RepresentDog,
  SubTextDiv,
} from "./style/endingStyle";
import Sharebtn from "./content/Sharebtn";

export type mbtiObjType = mbtiObjInterface | null;
interface mbtiObjInterface {
  id: number;
  src?: string;
  mbti: string;
  mbtiTitle: string;
  tag: string[];
  representative: string;
  subtextList: string[];
}
function EndingPage({
  mbti,
  mbtiObj,
  setCountPage,
  setShowMain,
  isMobile,
  shareSum,
}: {
  mbti: string;
  mbtiObj: mbtiObjType;
  setCountPage: React.Dispatch<React.SetStateAction<number>>;
  setShowMain: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  shareSum: number;
}) {
  return (
    <div className="slide-in" style={{ marginBottom: "1rem" }}>
      <IllustantionDiv>
        <div>
          <MbtiTitle>{mbtiObj?.mbti}</MbtiTitle>
          <RepresentDog>대표견종: {mbtiObj?.representative}</RepresentDog>
        </div>
        <img src={mbtiObj?.src} alt={mbtiObj?.mbti} />
      </IllustantionDiv>
      <div>
        <EndingTageDiv>
          {mbtiObj &&
            mbtiObj?.tag.map((e: string) => {
              return <div>#{e}</div>;
            })}
        </EndingTageDiv>
        <SubTextDiv>
          <ul>
            {mbtiObj &&
              mbtiObj?.subtextList.map((e: string) => {
                return <li>{e}</li>;
              })}
          </ul>
        </SubTextDiv>
      </div>
      <GoSurveyDiv>
        <div style={{ cursor: "pointer" }}>
          추첨을 통해 스타벅스 기프티콘을 드려요!
        </div>
        <p
          className="gotext"
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLScZ03hp0RAbFps2G2Jf_9G2z7TA0DAaGk5Sj0EarTxrGjXrVw/viewform?usp=sf_link",
              "_blank"
            )
          }
        >
          멍bti 설문에 참여하러 가기 &gt;
        </p>
      </GoSurveyDiv>
      <MainButton>
        <ShareListDiv>
          <Sharebtn isMobile={isMobile} shareSum={shareSum} />
        </ShareListDiv>
        <button
          style={{ margin: "10px 20px" }}
          onClick={() => {
            setCountPage(0);
            setShowMain(true);
          }}
        >
          테스트 다시 하기
        </button>
      </MainButton>
    </div>
  );
}
export default EndingPage;
