import React, { useEffect } from "react";
import "./App.css";
import mainDog from "../src/asset/lottie/mainDog.json";
import {
  IllustantionDiv,
  MainButton,
  MainDesktopDiv,
  MainMobileDiv,
  Progressbar,
  // PictureDiv,
  ShareListDiv,
  StartPage,
  SubText,
  TitleDiv,
} from "./style/mainStyle";
import { questionSet } from "./content/QuestionSet";
import QuestionPage from "./components/QuestionPage";
import EndingPage, { mbtiObjType } from "./EndingPage";
import Sharebtn from "./content/Sharebtn";
import { mbtiList } from "./content/mbtiList";
import { postCount } from "./api/api";
import Lottie from "lottie-react";

function App() {
  const [dogSum, setDogSum] = React.useState(283);
  const [shareSum, setShareSum] = React.useState(0);
  const [showMain, setShowMain] = React.useState(true);
  const [countPage, setCountPage] = React.useState(0);
  const [slideCss, setSlideCss] = React.useState("slide-out");
  const [progressWidth, setProgressWidth] = React.useState(0);
  const [mbti, setMbti] = React.useState("");
  const [choose, setChoose] = React.useState([""]);
  const [mbtiObj, setMbtiObj] = React.useState<mbtiObjType | null>(null);
  const [isShowProgress, setIsShowProgress] = React.useState(true);
  const [isMobile, setIsMobile] = React.useState(false);

  // 선책한 답변을 통해 MBTI를 찾는 함수
  const findElement = (arr: string[], stacks: string[]) => {
    let a = 0;
    let b = 0;
    let result;
    arr.filter((element) => element === stacks[0] && (a = a + 1));
    arr.filter((element) => element === stacks[1] && (b = b + 1));
    a > b ? (result = stacks[0]) : (result = stacks[1]);
    return result;
  };

  // 백엔드 배포 후 주석 해제
  async function fetchData() {
    // let total = await getCount();
    // setDogSum(total.totalCount);
  }

  // 디바이스 종류 확인하고 다른 종류의 styled-componenet 리턴
  const getMainComponent = (isMobile: boolean) => {
    if (isMobile) {
      return MainMobileDiv;
    } else {
      return MainDesktopDiv;
    }
  };

  // 첫 랜더링 때 디바이스 종류 확인
  useEffect(() => {
    window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  // 페이지 이동시마다 실행
  useEffect(() => {
    if (countPage > questionSet.length) {
      setIsShowProgress(false);
      setProgressWidth(100);
      setShowMain(false);
      const ranges = [
        [0, 3],
        [3, 6],
        [6, 9],
        [9, 12],
      ];
      const [eOrI, nOrS, fOrt, pOrj] = ranges.map((range) =>
        choose.slice(...range)
      );

      let mbtiArr = [];
      mbtiArr.push(findElement(eOrI, ["I", "E"]));
      mbtiArr.push(findElement(nOrS, ["S", "N"]));
      mbtiArr.push(findElement(fOrt, ["F", "T"]));
      mbtiArr.push(findElement(pOrj, ["J", "P"]));
      let result = mbtiArr.join("");
      let imgObj = mbtiList.filter((mbtiImg) => mbtiImg.mbti === result);

      setMbtiObj((prev) => imgObj[0]);
      setMbti((prev) => result);
      postCount(mbti);
    } else if (countPage === 0) {
      setIsShowProgress(false);
      setChoose((prev) => []);
      setProgressWidth(0);
      setShowMain(true);
    } else {
      setIsShowProgress(true);
      setProgressWidth((countPage / questionSet.length) * 100);
    }
  }, [countPage]);

  // 메인 페이지 class로 slide-in, slide-out 효과 주기
  useEffect(() => {
    fetchData();
    if (showMain) {
      setSlideCss("slide-in");
    } else {
      setSlideCss("slide-out");
    }
  }, [showMain]);

  const MainComponent = (
    <StartPage className={slideCss}>
      <IllustantionDiv>
        <TitleDiv>너의 MBTI가 궁금해!</TitleDiv>
        <Lottie animationData={mainDog} style={{ height: "100%" }}></Lottie>
      </IllustantionDiv>
      <MainButton>
        <button
          onClick={() => {
            setCountPage(1);
            setShowMain(false);
          }}
        >
          START
        </button>
        <SubText>지금까지 총 {dogSum} 마리가 mbti를 알아갔어요!</SubText>
      </MainButton>
      <Sharebtn isMobile={isMobile} shareSum={shareSum} />
    </StartPage>
  );

  const MainComponentOrAnother = getMainComponent(isMobile);

  return (
    <div className="App">
      <MainComponentOrAnother id="main">
        <div className="header">
          <img src="/inLineLogo.png" alt="Logo" />
        </div>
        {isShowProgress && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Progressbar>
              <div
                style={{
                  width: `${(countPage / questionSet.length) * 100}%`,
                  transition: "width 0.5s ease",
                  borderRadius: "15px",
                }}
              />
            </Progressbar>
          </div>
        )}

        {countPage > questionSet.length ? (
          <EndingPage
            mbti={mbti}
            mbtiObj={mbtiObj}
            setCountPage={setCountPage}
            setShowMain={setShowMain}
            isMobile={isMobile}
            shareSum={shareSum}
          ></EndingPage>
        ) : showMain ? (
          MainComponent
        ) : (
          <QuestionPage
            countPage={countPage}
            setCountPage={setCountPage}
            choose={choose}
            setChoose={setChoose}
          ></QuestionPage>
        )}
      </MainComponentOrAnother>
    </div>
  );
}

export default App;
