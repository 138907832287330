import { keyframes, styled } from "styled-components";
const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const EndingTageDiv = styled.div`
  padding: 10px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  & > div {
    text-decoration: underline;
    background: #ffffffb8;
    padding: 5px 10px;
    border-radius: 15px;
  }
`;

export const SubTextDiv = styled.div`
  background: #ffffffb8;
  font-weight: 600;
  width: 80%;
  margin: auto;
  padding: 10px 20px;
  border-radius: 15px;
  & > ul {
    margin: 0;
    list-style: none;
    text-align: center;
    padding: 0;
    line-height: 2;
    font-size: 1.2em;
  }
`;
export const RepresentDog = styled.div`
  font-size: 1.4rem;
`;
export const CloseButton = styled.button`
  border: none;
  background: none;
  font-size: 1.5rem;
  color: black;
`;

export const ShareModal = styled.div`
  animation: ${slideInFromBottom} 0.5s ease-out forwards;
  position: absolute;
  bottom: 25px;
  width: 20rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  border-radius: 10px;
  & > div {
    display: flex;
    gap: 10px;
    width: 100%;
    & > input {
      text-align: center;
      width: 100%;
      border: 1px solid #ffca35;
      border-radius: 10px;
      padding: 5px;
    }
    & > button {
      color: black;
      width: 6rem;
      border: none;
      border-radius: 10px;
      padding: 5px;
      background: #ffca35;
      font-size: 1.2rem;
      &:hover {
        background: #cca129;
      }
    }
  }
`;
export const Modalbackground = styled.div`
  position: absolute;
  background: #27272782;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  left: 0;
`;

export const GoSurveyDiv = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  background-color: #ffffff4b;
  font-size: 1.2rem;
  & > .gotext {
    font-size: 1.4rem;
    font-weight: 500;
    background-color: #ffca35;
    border: 1px solid #fff4d5;
    width: fit-content;
    margin: 0.5rem auto 0;
    padding: 5px 10px;
    border-radius: 15px;
    &:hover {
      background-color: #cca129;
    }
  }
  & > p {
    margin: 0;
  }
`;
