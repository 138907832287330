import { styled } from "styled-components";

export const QuestionDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const QuestionTitle = styled.div`
  max-width: 76vw;
  font-size: 1.5rem;
  font-weight: 600;
`;
export const AnswerDiv = styled.div`
  width: 84%;
  margin: 5% auto;
  & > div {
    padding: 4% 0;
    margin: 5% 0;
    border-radius: 50px;
    background: white;
    font-size: 1.2rem;

    &:hover {
      background-color: #ffcf45;
    }
  }
`;
export const BeforeHeader = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  & > button {
    border: none;
    background: transparent;
    padding: 0;
  }
`;
export const HomeBtn = styled.button`
  color: black;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 1.2rem;
  border: none;
  background-color: #87b467;
  border-radius: 10px;
  &:hover {
    background-color: #61824a;
  }
`;
export const MbtiTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;
