import axios from "axios";

export const getCount = async () => {
  try {
    const res = await axios.get("http://localhost:3000/getCount");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const postCount = (mbti: string) => {
  axios
    .post("http://localhost:3000/addCount", { mbti: mbti })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
