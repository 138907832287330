export const mbtiList = [
  {
    id: 0,
    src: "/dogs/mountainDog.png",
    mbti: "ISTJ",
    // QIWC
    mbtiTitle: "침착한 주도자",
    tag: ["쉿, 조용한 성격", "헌신적", "규칙과 질서", "애교좀..", "츤데레"],
    representative: "버니즈 마운틴 독",
    subtextList: [
      "부산스럽지 않고 조용해요.",
      "규칙과 질서를 중요하게 생각해요.",
      "애교는 좀 부족해요.",
      "츤데레 성향이 있어요.",
      "헌신적이고 충실해요.",
    ],
  },
  {
    id: 1,
    src: "/dogs/sibadog.png",
    mbti: "ISTP",
    // QIAC
    mbtiTitle: "눈치빠른 탐험가",
    tag: ["의심 대마왕", "고집 불통", "마이웨이"],
    representative: "시바견",
    subtextList: [
      "의심이 많아요.",
      "고집이 세고, 마이웨이에요.",
      "눈치가 빨라요.",
      "탐구하고자 하는 성향이 있어요.",
    ],
  },
  {
    id: 2,
    src: "/dogs/bbabbi.png",
    mbti: "ISFJ",
    // QIWF
    mbtiTitle: "신비로운 관찰자",
    tag: ["섬세함", "예민함", "눈치 빠름", "질투 대마왕"],
    representative: "빠삐용",
    subtextList: [
      "예민하고 섬세해요.",
      "눈치가 빨라서 주인의 기분을 잘 알아요.",
      "질투심이 강해요.",
      "주변을 잘 관찰해요.",
    ],
  },
  {
    id: 3,
    src: "/dogs/pug.png",
    mbti: "ISFP",
    // QIAF
    mbtiTitle: "느긋한 모범생",
    tag: ["조용함", "순둥 순둥", "태평양 마음", "사람 최고"],
    representative: "퍼그",
    subtextList: [
      "은근 조용해요.",
      "순둥순둥한 성격이에요.",
      "사람을 좋아해요.",
      "태평양 같이 넓은 마음을 가지고 있어요.",
    ],
  },
  {
    id: 4,
    src: "/dogs/huski.png",
    mbti: "INTJ",
    // EIWC
    mbtiTitle: "재치 있는 전략가",
    tag: ["츤츤데레", "무뚝뚝", "좋아하는 사람만 봄"],
    representative: "시베리안 허스키",
    subtextList: [
      "무뚝뚝한데 다정해요.",
      "츤데레에요.",
      "좋아하는 사람에게만 다정해요.",
      "좋고 싫음이 분명해요.",
    ],
  },
  {
    id: 5,
    src: "/dogs/bigul.png",
    mbti: "INTP",
    // EIAC
    mbtiTitle: "소심한 흥부자",
    tag: ["빈둥빈둥 강아지", "움직임이 느림", "남한테 관심 없음"],
    representative: "비글",
    subtextList: [
      "움직이기 별로 안좋아해요.",
      "하지만 내적 흥은 많을 수 있어요.",
      "남한테 관심이 없어요.",
      "느긋느긋해요.",
    ],
  },
  {
    id: 6,
    src: "/dogs/jindo.png",
    mbti: "INFJ",
    // EIWF
    mbtiTitle: "선량한 신비주의자",
    tag: ["조용힘", "눈치 빠름", "대담", "좋아하는 사람한테 잘해줌"],
    representative: "진돗개",
    subtextList: [
      "조용하게 눈치를 잘 봐요.",
      "은근 대담한 성격이에요.",
      "좋아하는 사람에게 잘해줘요.",
      "눈치가 빠른만큼 주변을 잘 챙겨요.",
    ],
  },
  {
    id: 7,
    src: "/dogs/poo.png",
    mbti: "INFP",
    // EIAF
    mbtiTitle: "진취적인 수호자",
    tag: ["민감함", "여린 마음", "다정다감", "친절함", "꽤나 사교적"],
    representative: "푸들",
    subtextList: [
      "민감하고 여린 마음을 가지고 있어요.",
      "다정다감하고 친절해요.",
      "사교적이에요.",
      "마음이 여린만큼 상처를 잘 받을 수 있어요.",
    ],
  },
  {
    id: 8,
    src: "/dogs/shepherd.png",
    mbti: "ENTJ",
    // ESWC
    mbtiTitle: "민첩한 활동가",
    tag: ["첵임감 강함", "붙임성 좋음", "완벽쟁이", "듬직함"],
    representative: "셰퍼드",
    subtextList: [
      "책임감이 강해요.",
      "완벽주의자에요.",
      "붙임성이 강해요.",
      "듬직해요.",
      "잘 챙겨주는 성격이에요.",
    ],
  },
  {
    id: 9,
    src: "/dogs/bishong.png",
    mbti: "ENTP",
    // ESAC
    mbtiTitle: "자유로운 영혼의 협상가",
    representative: "비숑",
    tag: ["정이 많음", "온화", "호기심 많음", "다 해야봐야함", "에너자이저"],
    subtextList: [
      "정이 많고 온화해요.",
      "호기심이 많아서 다 해봐야해요.",
      "에너자이저에요.",
      "다양한 것을 해보고 싶어해요.",
      "냄새 맡는 것을 많이 좋아해요.",
    ],
  },
  {
    id: 10,
    src: "/dogs/goldenDog.png",
    mbti: "ENFJ",
    // ESWF
    mbtiTitle: "용감한 사냥꾼",
    representative: "골든 리트리버",
    tag: ["새로운거 좋아함", "이타적", "친절", "감수성 풍부"],
    subtextList: [
      "새로운 것을 좋아해요.",
      "이타적이고 친절해요.",
      "감수성이 풍부해요.",
      "친절한만큼 상처를 잘 받을 수 있어요.",
      "착한 성격이에요.",
    ],
  },
  {
    id: 11,
    src: "/dogs/pom.png",
    mbti: "ENFP",
    // ESAF
    mbtiTitle: "천진난만한 탐구자",
    representative: "포메라니안",
    tag: ["핵인싸", "주인공 병", "기분파", "친구 많음"],
    subtextList: [
      "완전 세상의 주인공 체질이에요.",
      "친구가 많아요.",
      "기분파에요. 그래서 예민할 수 있어요.",
      "원하는게 많아요.",
    ],
  },
  {
    id: 12,
    src: "/dogs/coli.png",
    mbti: "ESTJ",
    // QSWC
    mbtiTitle: "영리한 활동가",
    representative: "보더콜리",
    tag: ["진취적", "활동적", "날 인정해", "똑똑이"],
    subtextList: [
      "진취적이고 자기주도적이에요.",
      "활동적이고 에너지가 넘쳐요.",
      "인정욕구 혹은 칭찬 받고 싶은 욕구가 강해요.",
      "인정 받고 싶어서 노력해요.",
      "똑똑해요.",
    ],
  },
  {
    id: 13,
    src: "/dogs/terri.png",
    mbti: "ESTP",
    // QSAC
    mbtiTitle: "여유로운 전략가",
    representative: "잭 러셀 테리어",
    tag: [
      "자유분방",
      "날 묶어두지 마",
      "내기 중독",
      "경쟁 좋아함",
      "자신감 뿜뿜",
    ],
    subtextList: [
      "자유분방한 성격이에요.",
      "갇혀있는 것을 싫어해요.",
      "내기 혹은 경쟁을 매우 즐겨요.",
      "자신감이 넘쳐요.",
      "두려움이 적어요.",
    ],
  },
  {
    id: 14,
    src: "/dogs/docs.png",
    mbti: "ESFJ",
    // QSWF
    mbtiTitle: "열정적인 탐구자",
    representative: "닥스훈트",
    tag: [
      "다정함",
      "의리견",
      "애교 뿜뿜",
      "새로운 관계 좋아함",
      "친구 많고 싶음",
    ],
    subtextList: [
      "다정하고 친절해요.",
      "의리가 강해요.",
      "애교가 많아요.",
      "새로운 관계를 좋아해요.",
      "잘 다가가요.",
    ],
  },
  {
    id: 15,
    src: "/dogs/prenchi.png",
    mbti: "ESFP",
    // QSAF
    mbtiTitle: "천진난만한 안내자",
    representative: "프렌치불독",
    tag: ["활동적", "말 잘 들음", "적극적", "들이대", "호기심 대마왕"],
    subtextList: [
      "활동적이에요.",
      "말을 잘 들으려고 해요.",
      "적극적이에요. 다만 과할 수 있어요.",
      "들이대는게 익숙해요.",
      "호기심이 많아요.",
    ],
  },
];
// 독립지향 / 관계지향 (I === I / E ===S)
// 여기까지 활동성향 -> 동적 성향 / 정적성향 (N===E/S===Q)
// 여기까지 순종 -> 충직함 / 영민함 (F === F/T === C)
// 여기까지 적응 성향 -> 능동형 / 신중형 (P === A /J === W)

//참고 자료 : https://cafe.daum.net/rocksoccer/ADs1/1179213?svc=cafeapi
